import crypto from "crypto";
import jsrsasign from 'jsrsasign'

let rsa_public_key = "b3764d7f25497d340ae81953af7a898e1c1ea320489b17868906c537b33b6446f24ce902227db22540bfd80da256b4fc53b385ea7a4233a4e1e02d0be19aac8fac1e23830de13968bb089f767533716c03032172f8e32e2f105e22ff12ecc0f0ffae68b70f70fa6994dafd56e011178b339791b762413a29d1960b4fbe133c50bc8c3bf11bd985309440d1b9e3770dcc291f91e5388fe95dee06b2de9ca0ad35c0b89ba9e2ac282276bb09f477f3d0ffc5384f245b900b4d17eb3d16129d0666f02668459ea987531ab8b00c184ffd198d74114ccf34691ba63152812c3f4bbd415fef1770f9b4a4a178ae89eed96fd99c73ff750356a5905ae268a8cdff0657";
let rsa_public_exponent = "10001";

// rsa加密
// 不可加密数字，否则解密为空
function rsa(text) {
    let pub = jsrsasign.KEYUTIL.getKey({ n: rsa_public_key, e: rsa_public_exponent })
    let enc = jsrsasign.KJUR.crypto.Cipher.encrypt(text, pub)
    return `rsa.${enc}`
}

// md5加密
function md5(str) {
    let md5 = crypto.createHash("md5");
    md5.update(str);
    let md5Result = md5.digest("hex");
    return md5Result;
}

// aes 解密
const CryptoJS = require('crypto-js');
const base32 = require('base32.js');

function byteArrayToBase64(byteArray) {
    return btoa(String.fromCharCode.apply(null, byteArray));
}
function base64ToString(base64) {
    return atob(base64);
}
// 已知密钥
const secretKey = 'da4756119d5e7e0f';
function decryptAES_new(encryptedData) {
  // 将密钥解析为字节数组
  const key = CryptoJS.enc.Utf8.parse(secretKey)

  // 使用 base32.js 解码 Base32 编码的数据
  const decodedBytes = base32.decode(encryptedData);
  // 转换成字符串
  const base64String = byteArrayToBase64(decodedBytes);
  const decodedBytesString = base64ToString(base64String);

  // 使用AES解密 (ECB 模式，不需要 IV)
  const bytes = CryptoJS.AES.decrypt(decodedBytesString, key, {
    mode: CryptoJS.mode.ECB, // 修改为 ECB 模式
    padding: CryptoJS.pad.Pkcs7, // 使用 PKCS#7 填充
  })

  // 将解密后的字节数据转换为UTF-8字符串
  return bytes.toString(CryptoJS.enc.Utf8)
}

export {rsa, md5, decryptAES_new}